<template lang="pug">
  div
    customer-order-table
</template>
<script>
export default {
  name: 'CustomerOrders',
  components: {
    customerOrderTable: () => import('./Table'),
  },
}
</script>